import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text, Button } from "../../components/Core";
import { device } from "../../utils";
import { StaticImage } from "gatsby-plugin-image";
import FeaturedLogos from "./FeaturedLogos";

const ImgRight = styled(Box)`
  @media ${device.sm} {
  }
`;

const CtaButton = styled(Button)`
  height: 64px;

  @media ${device.md} {
    height: 72px;
    width: 240px;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section
        bg="#81b29a"
        className="position-relative"
        pb="60px !important"
        id="heroSection"
      >
        <div className="pt-5 pt-lg-0"></div>
        <Container>
          <Row className="align-items-center position-relative">
            <Col md="7" xl="6" className="offset-xl-1 order-md-2 mb-3 mb-sm-0">
              <div
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
                data-aos-anchor="#heroSection"
              >
                <Box className="d-flex d-md-block flex-column">
                  <Title>
                    Stress Less.
                    <br className="d-none d-sm-block" />
                    Spend Better.
                    <br className="d-none d-sm-block" />
                    Save Enough.
                  </Title>
                  <Box mb={4}>
                    <Text color="dark" fontSize="20px">
                      Pay attention to your well-being, not just your numbers.
                    </Text>
                  </Box>
                  <CtaButton
                    onClick={() => {
                      window.location.href = "/download/";
                    }}
                  >
                    Try Allo for Free
                  </CtaButton>
                  <FeaturedLogos />
                </Box>
              </div>
            </Col>
            <Col
              md="5"
              xl="4"
              className="position-static pb-5 order-md-1"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              data-aos-once="true"
              data-aos-anchor="#heroSection"
            >
              <ImgRight>
                <StaticImage
                  src="../../assets/image/png/hero-screenshot.png"
                  alt="Screenshot"
                  loading="eager"
                  placeholder="none"
                  quality={100}
                />
              </ImgRight>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
