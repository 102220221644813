import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Text } from "../../components/Core";
import { FaqCard } from "../../components/FaqCard";

const SectionStyled = styled(Section)``;

const FAQ_ITEMS = [
  [
    "Is Allo Secure?",
    "Allo uses bank-level security, and we do not store your financial credentials. We will never show you distracting ads or sell your financial data.",
  ],
  [
    "Will you show me ads or sell my data?",
    "Absolutely not. Allo is ad-free, and we never sell your data. Your privacy is our utmost priority.",
  ],
  [
    "Do you offer a free trial?",
    "Yes, we offer a free trial of Allo with full access to all features. This allows you to experience everything Allo has to offer and ensure it’s a great fit for you.",
  ],
  [
    "How much does Allo cost?",
    "Allo's subscription is priced at $49.99 per year, which comes to around $4 per month. We believe in making Allo accessible to all, so if you can’t afford it now, reach out to us, and we'll provide it for free.",
  ],
  [
    "How do I apply for a scholarship?",
    "To apply for a scholarship, simply send an email to team@allo.finance, expressing your need. We're here to support you.",
  ],
  [
    "What about budgeting?",
    "If budgeting works for you — great!  The reality is that the ability to make and stick to a budget defies the realities of most people's lives.  Expenses fluctuate a lot every month and we are generally too optimistic about what a reasonable budget should be.  This requires a tremendous amount of willpower to stick to a budget and most of us end up giving up.",
  ],

  [
    'Does the word "budget" make you run away?',
    "Us too!  That’s why we built Allo.  It’s for the majority of us that don’t love to budget. Being aware doesn't mean limiting yourself, it just means noticing what you have spent.",
  ],

  [
    "This doesn’t sound like fun.",
    "While we may not offer amusing cat videos, Allo will bring you a sense of gratitude and joy through better financial awareness.",
  ],

  [
    "I already know how I spend. How will Allo help?",
    "That's great! We think finding balance among the many priorities we have in life is a journey, not a destination.  With Allo's regular practice, you can continue to work towards that balance and make your money go further towards the life you want to live.",
  ],

  [
    "I’m not sure I want to know where I’m spending my money.",
    "We hear you.  Money avoidance is very common.  As you become aware of your spending some things will surprise you and some will bother you.  As you become more aware you can then choose to make the changes that feel right for you.",
  ],
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(-1);
  return (
    <>
      <SectionStyled id="faq">
        <Container>
          <Row className="justify-content-center">
            <Col xl="7" lg="8" md="9">
              <div className="text-center">
                <Title>FAQ</Title>
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            {FAQ_ITEMS.map(([title, text], index) => (
              <Col lg="6" className="mt-3" key={title}>
                <FaqCard
                  title={title}
                  onClick={() => {
                    if (index === openIndex) {
                      setOpenIndex(-1);
                    } else {
                      setOpenIndex(index);
                    }
                  }}
                  open={index === openIndex}
                >
                  <div>
                    <Text variant="small" color="darkShade" pt="12px">
                      {text}
                    </Text>
                  </div>
                </FaqCard>
              </Col>
            ))}
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default FAQ;
