import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { rgba } from "polished";
import { Title, Section, Box, Text } from "../../components/Core";
import { device, breakpoints } from "../../utils";
import { StaticImage } from "gatsby-plugin-image";

const Screenshot1 = () => (
  <StaticImage
    src="../../assets/image/png/how-it-works-1.png"
    alt="Screenshot 1"
    placeholder="blurred"
    quality={100}
  />
);
const Screenshot2 = () => (
  <StaticImage
    src="../../assets/image/png/how-it-works-2.png"
    alt="Screenshot 2"
    placeholder="blurred"
    quality={100}
  />
);
const Screenshot3 = () => (
  <StaticImage
    src="../../assets/image/png/how-it-works-3.png"
    alt="Screenshot 3"
    placeholder="blurred"
    quality={100}
  />
);
const Screenshot4 = () => (
  <StaticImage
    src="../../assets/image/png/how-it-works-4.png"
    alt="Screenshot 4"
    placeholder="blurred"
    quality={100}
  />
);
const Screenshot5 = () => (
  <StaticImage
    src="../../assets/image/png/how-it-works-5.png"
    alt="Screenshot 5"
    placeholder="blurred"
    quality={100}
  />
);
const Screenshot6 = () => (
  <StaticImage
    src="../../assets/image/png/how-it-works-6.png"
    alt="Screenshot 6"
    placeholder="blurred"
    quality={100}
  />
);

const SectionStyled = styled(Section)``;

const SliderStyled = styled(Slider)`
  position: relative;
  .slick-dots {
    position: relative;
    margin-top: 10px;

    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.shadow};
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    font-size: 0;
    height: 65px;
    display: flex;
    width: 65px;
    background: ${({ theme }) => theme.colors.secondary};
    transition: 0.4s;
    border-radius: 500px;
    transform: translate(-50%, -50%);
    z-index: 999;
    margin-top: -20px;
    &::before {
      position: absolute;
      content: "";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      transition: 0.4s;
    }
  }
  .slick-arrow.slick-prev {
    left: 0;
    &:before {
      content: "\f060";
    }
    @media ${device.md} {
      /* margin-left: 60px; */
    }
  }

  .slick-arrow.slick-next {
    right: 0;
    left: auto;
    transform: translate(50%, -50%);
    &:before {
      content: "\f061";
    }
    @media ${device.md} {
      /* margin-right: 60px; */
    }
  }

  .slick-arrow:hover,
  .slick-arrow:focus {
    background: ${({ theme }) => theme.colors.light}!important;
    color: ${({ theme }) => theme.colors.secondary}!important;
    &:before {
      color: ${({ theme }) => theme.colors.secondary}!important;
    }
  }
`;

const SliderItem = styled(Box)`
  &:focus {
    outline: none;
  }
`;

const SliderCard = styled(Box)`
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  box-shadow: ${({ theme }) =>
    `0 12px 34px -11px ${rgba(theme.colors.shadow, 0.125)}`};

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 64px;

  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 48px;

  @media ${device.md} {
    padding-right: 50px;
    padding-left: 50px;
    margin-bottom: 96px;
  }
  @media ${device.lg} {
    margin: 60px 12px 100px !important;
    padding-right: 72px;
    padding-left: 72px;
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: row;
    align-items: center;
  }
  @media ${device.xl} {
    padding-right: 72px;
    padding-left: 72px;
  }
`;

const NumberBlock = styled(Box)`
  display: inline-block;
  min-width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: #eae9f2;
  margin-right: 12px;
  margin-bottom: 24px;
  text-align: center;
  align-self: flex-start;

  span {
    color: ${({ theme }) => theme.colors.ash};
    font-size: 30px;
    font-weight: 900;
  }
`;

const SliderText = styled(Box)`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 24px;
  @media ${device.md} {
    padding-left: 18px;
  }
  @media ${device.lg} {
    padding-top: 0;
    padding-left: 20px;
    padding-right: 0;
  }
  @media ${device.xl} {
    padding-left: 60px;
  }
`;

const SliderScreenshotContainer = styled(Box)`
  margin-left: 12px;
  margin-right: 12px;

  @media ${device.md} {
    margin-left: 48px;
    margin-right: 48px;
  }

  @media ${device.lg} {
    margin-left: 24px;
    margin-right: 24px;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 400px !important;
    min-width: 360px !important;
  }

  @media ${device.xl} {
    min-width: 400px !important;
  }
`;

const HowItWorks = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: breakpoints.lg,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const sliderItems = [
    <SliderItem key={1}>
      <SliderCardComponent
        number={1}
        title="Review Your Numbers"
        Screenshot={Screenshot1}
        content={
          <>
            See all transactions across your bank accounts and credit cards in
            one place.
            <br />
            <br />
            Allo automatically surfaces everything since your last session, so
            you never miss anything.
          </>
        }
      />
    </SliderItem>,
    <SliderItem key={2}>
      <SliderCardComponent
        number={2}
        title="Tag and Flag"
        Screenshot={Screenshot4}
        content={
          <>
            Be aware of the values that motivate your spending. Tag the values
            you want to track or celebrate. Flag things that deserves a second
            look.
            <br />
            <br />
            Offload your to-do’s — things you don’t recognize, items to be
            refunded, or subscriptions to cancel — so you don't carry them in
            your head all the time.
          </>
        }
      />
    </SliderItem>,
    <SliderItem key={3}>
      <SliderCardComponent
        number={3}
        title="Emotions Matter"
        Screenshot={Screenshot5}
        content={
          <>
            Check in with how you are feeling about your finances, jot down a
            note, and notice anything that you are grateful for.
          </>
        }
      />
    </SliderItem>,
    <SliderItem key={4}>
      <SliderCardComponent
        number={4}
        title="Sit Back and Relax"
        Screenshot={Screenshot6}
        content={
          <>
            Some call it "inbox zero", others call it "that zen feeling". Allo
            only surfaces things you haven’t seen, so when you’ve reviewed
            everything, you know you are done.
            <br />
            <br />
            No ads. No spam. No unwanted notifications.
            <br />
            <br />
            Set down your phone and spend the rest of the time outside of the
            numbers.
          </>
        }
      />
    </SliderItem>,
  ];

  return (
    <>
      {/* <!-- testimonial section --> */}
      <SectionStyled bg="#f6f6f8" pb={["0px!important"]} id="how-it-works">
        <Container>
          <Row className="justify-content-center mb-4">
            <Col lg="10">
              <div className="text-center">
                <Title>How Does It Work?</Title>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="12" className="pb-5">
              <SliderStyled className="d-none d-lg-block" {...slickSettings}>
                {sliderItems}
              </SliderStyled>
              <Box className="d-lg-none">{sliderItems}</Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

const SliderCardComponent = ({ number, title, content, Screenshot }) => (
  <SliderCard>
    <SliderScreenshotContainer>
      <Screenshot />
    </SliderScreenshotContainer>
    <SliderText>
      <NumberBlock>
        <span>{number}</span>
      </NumberBlock>
      <Title variant="card" mb={4} mt={2} style={{ display: "inline" }}>
        {title}
      </Title>
      <Text color="dark">{content}</Text>
    </SliderText>
  </SliderCard>
);

export default HowItWorks;
